import * as FDS from "@arctravel/react-fds/lib";
declare const window: any;

const GLOBALDATA = "GLOBAL"

export const ARCCIDSTemplate: any = {
    reportName: "ARCCIDS",
    darkBackground: true,
    staticFilter: true,     // This makes the left pannal not collapsable
    info: "",
    hideSavedTemplates: false,
    noResultsProp: true,
    hideResultsTitle: true,
    disablePagination: true,
    stopLoading: true,
    lists: [{
        label: "Filters",
        colorName: "",
        wraps: [{
            name: "Filters",
            isTitle: true,
            fields: []
        }, {
            name: "corporation",
            showName: false,
            fields: [{
                name: "corporation",
                label: "Corporation",
                help: "Enter one or more corporation ids",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "carr_code",
                minChar: 0,
                url: window.ENV.references.corporate,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.corporate) || '[]'),
                placeholder: "Corporation"
            }]
        }, {
            name: "tmcgroup",
            showName: false,
            fields: [{
                name: "tmcgroup",
                label: "TMC Group",
                help: "Enter one or more TMC Group ids",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 0,
                url: window.ENV.references.agent_group,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agent_group) || '[]'),
                placeholder: "All TMC Groups"
            }]
        }, {
            name: "tmc",
            showName: false,
            fields: [{
                name: "tmc",
                label: "TMC",
                help: "Enter one or more TMC ids",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "description",
                codeKey: "code",
                minChar: 0,
                url: window.ENV.references.agent,
                outputLabel: true,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.agent) || '[]'),
                placeholder: "Agent(s)"
            }]
        }, {
            name: "dra_status",
            showName: false,
            fields: [{
                name: "dra_status",
                label: "DRA Status",
                help: "Select one or a combination of DRA status types",
                type: "typeahead",
                labelKey: "value",
                codeKey: "key",
                hideKey: true,
                minChar: 0,
                color: 'c',
                matchedOnly: true,
                selectAll: true,
                value: [],
                options: [
                    { key: "Approved", value: "Approved", checked: true },
                    { key: "Cancelled", value: "Cancelled", checked: true },
                    { key: "Out for Approval", value: "Out for Approval", checked: true },
                    { key: "Email Viewed", value: "Email Viewed", checked: true },
                ],
                placeholder: "All"
            }]
        }, {
            name: "data_status",
            showName: false,
            fields: [{
                name: "data_status",
                label: "Data Status",
                help: "Select one or a combination of data status types",
                type: "typeahead",
                labelKey: "value",
                codeKey: "value",
                hideKey: true,
                minChar: 0,
                color: 'c',
                matchedOnly: true,
                selectAll: true,
                value: [],
                options: [
                    { key: "Approved", value: "No Data Received - No Tour Code Tickets", checked: true },
                    { key: "Approved", value: "No Data Received - With Tour Code Tickets", checked: true },
                    { key: "Approved", value: "Receiving Incomplete U.S. Data", checked: true },
                    { key: "Approved", value: "Receiving Incomplete Non-U.S. Data", checked: true },
                    { key: "Approved", value: "Receiving Incomplete U.S. & Non-U.S. Data", checked: true },
                    { key: "Approved", value: "Receiving Complete U.S. Data", checked: true },
                    { key: "Approved", value: "Receiving Complete Non-U.S. Data", checked: true },
                    { key: "Approved", value: "Receiving Complete U.S. & Non-U.S. Data", checked: true },
                ],
                placeholder: "All"
            }]
        }, {
            name: "account_type",
            showName: false,
            fields: [{
                name: "account_type",
                label: "Account Type",
                help: "Select one or a combination of account types",
                type: "typeahead",
                labelKey: "value",
                codeKey: "key",
                hideKey: true,
                minChar: 0,
                color: 'c',
                matchedOnly: true,
                selectAll: true,
                value: [],
                options: [
                    { key: "Global", value: "Global", checked: true },
                    { key: "MCTA", value: "MCTA", checked: true },
                    { key: "Other", value: "Other", checked: true },
                    { key: "Blank", value: "Blank", checked: true }
                ],
                placeholder: "All"
            }]
        }, {
            name: "countriesOfSale",
            showName: false,
            fields: [{
                name: "pos_country",
                label: "Countries Of Sale",
                rLabel: "Countries of Sale",
                type: "typeahead",
                matchedOnly: true,
                value: [],
                labelKey: "name",
                codeKey: "code",
                minChar: 0,
                disabled: true,
                enableFor: { dataSource: GLOBALDATA },
                show: true,
                url: window.ENV.references.country,
                options: [],
                localStorage: JSON.parse(localStorage.getItem("REF:" + window.ENV.references.country) || '[]')
            }]
        }, {
            name: "Date Information",
            help: "Select combination of date periods using the below options.",
            isTitle: true,
            showName: false,
            fields: [],
        }, {
            name: "DRA Sent Dates",
            showName: true,
            help: "",
            type: 'option',
            condition: 'OR',
            fields: [{
                name: "all_dra_sent_date",
                tab: "All",
                type: "radio",
                show: true,
                value: '',
                options: [{ key: "", value: "All DRA Sent Dates", disabled: true }]
            }, {
                tab: "By Date",
                name: "dra_sent_date",
                label: "By Date",
                type: "date",
                selectsRange: true,
                show: false,
                startDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 13)), "/", true, false)],
                endDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 7)), "/", true, false)],
            }]
        }, {
            name: "Approved Dates",
            showName: true,
            help: "",
            type: 'option',
            condition: 'OR',
            fields: [{
                name: "all_approved_date",
                tab: "All",
                type: "radio",
                show: true,
                value: '',
                options: [{ key: "", value: "All Approved Date", disabled: true }]
            }, {
                tab: "By Date",
                name: "approved_date",
                label: "By Date",
                type: "date",
                show: false,
                selectsRange: true,
                startDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 13)), "/", true, false)],
                endDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 7)), "/", true, false)],
            }]
        }, {
            name: "Ticketing Start Dates",
            showName: true,
            help: "",
            type: 'option',
            condition: 'OR',
            fields: [{
                name: "all_rlse_dt",
                tab: "All",
                type: "radio",
                show: true,
                value: '',
                options: [{ key: "", value: "All Ticketing Start Dates", disabled: true }]
            }, {
                tab: "By Date",
                name: "rlse_dt",
                label: "By Date",
                type: "date",
                selectsRange: true,
                show: false,
                startDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 13)), "/", true, false)],
                endDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 7)), "/", true, false)],
            }]
        }, {
            name: "Ticketing End Dates",
            showName: true,
            help: "",
            type: 'option',
            condition: 'OR',
            fields: [{
                name: "all_rlse_end_dt",
                tab: "All",
                type: "radio",
                show: true,
                value: '',
                options: [{ key: "", value: "All Ticketing End Dates", disabled: true }]
            }, {
                tab: "By Date",
                name: "rlse_end_dt",
                label: "By Date",
                type: "date",
                show: false,
                selectsRange: true,
                startDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 13)), "/", true, false)],
                endDate: [FDS.dateToText(new Date(new Date().setDate(new Date().getDate() - 7)), "/", true, false)],
            }]
        }]
    }]
};

