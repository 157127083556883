import React, { useState, useEffect } from 'react';
import { Row, Col, Breadcrumb, Alert, Modal, Button } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds';
import { DynamicPagination } from '../../utils/pagination/DynamicPagination';


import './index.scss';

const status: any = {
    "": "warning",
    "AGREEMENT_EMAIL_VIEWED": "success",
    "OUT_FOR_APPROVAL": "disabled",
    "Cancelled": "secondary",
    "PROCESSED": "success",
    "DELETED": "danger"
}

export const ARCCIDataStatus = (props: any) => {
    const [isTMC, setIsTMC] = useState(0);
    const [response, setResponse] = useState({ page1: { results: [] }, page2: { results: [] } });
    const [corps, setCorps]: any = useState({});
    const [tmcs, setTmcs]: any = useState({});

    const itemPerPage = 500, itemPerPage3 = 50;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage3, setCurrentPage3] = useState(1);

    const [modalData, setModalData]: any = useState({
        data: []
    });

    const statusTable = (r: any) => {
        return (
            <>
                <Row>
                    <Col sm={2}></Col>
                    <Col sm={2}>Receiving Data</Col>
                    <Col sm={2}>Current Data</Col>
                    <Col sm={2}>Historical Data</Col>
                    <Col sm={2}>Other Airline Data</Col>
                    <Col sm={2}>
                        <Button variant='link'
                            className='p-0'
                            onClick={() => setModalData({
                                data: [
                                    { label: "abc", value: 100 },
                                    { label: "pqr", value: 200 },
                                    { label: "xyz", value: 150 }
                                ]
                            })}>
                            Tour Code Validation
                        </Button>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm={2}>U.S. Point of Sale</Col>
                    <Col sm={2}>
                        <span className={`dot ${r.ASP_ANY_DATA?.toLowerCase() === 'yes' ? 'success' : 'danger'}`}></span>
                        {FDS.valueOrDash(r.ASP_ANY_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${r.ASP_UPDATED_DATA?.toLowerCase() === 'yes' ? 'success' : 'danger'}`}></span>
                        {FDS.valueOrDash(r.ASP_UPDATED_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${r.ASP_HISTORICAL_DATA?.toLowerCase() === 'yes' ? 'success' : 'danger'}`}></span>
                        <small className='type-caption-on-light'>{FDS.valueOrDash(r.ASP_HISTORICAL_DATA)}</small>                                                </Col>
                    <Col sm={2}>
                        <span className={`dot ${r.ASP_MARKET_SHARE?.toLowerCase() === "complete" ? 'success d100' : 'warning d50'}`}></span>
                        <small className='type-caption-on-light text-capitalize'>{FDS.valueOrDash(r.ASP_MARKET_SHARE)}</small>                                                </Col>
                    <Col sm={2}>
                        <span className={`dot d100 ${r.ASP_TOTAL_RATE_LTM < 0.9 ? 'success' : 'warning'}`}></span>
                        {FDS.valueOrDash(r.ASP_TOTAL_RATE_LTM ? FDS.numberFormatTxt(r.ASP_TOTAL_RATE_LTM * 100, 2, '%') : r.ASP_TOTAL_RATE_LTM)}
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col sm={2}>Non-U.S. Point of Sale</Col>
                    <Col sm={2}>
                        <span className={`dot ${r.BSP_ANY_DATA?.toLowerCase() === 'yes' ? 'success' : 'danger'}`}></span>
                        {FDS.valueOrDash(r.BSP_ANY_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${r.BSP_UPDATED_DATA?.toLowerCase() === 'yes' ? 'success' : 'danger'}`}></span>
                        {FDS.valueOrDash(r.BSP_UPDATED_DATA)}
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${r.BSP_HISTORICAL_DATA?.toLowerCase() === 'yes' ? 'success' : 'danger'}`}></span>
                        <small className='type-caption-on-light'>{FDS.valueOrDash(r.BSP_HISTORICAL_DATA)}</small>
                    </Col>
                    <Col sm={2}>
                        <span className={`dot ${r.BSP_MARKET_SHARE?.toLowerCase() === "complete" ? 'success d100' : 'warning d50'}`}></span>
                        <small className='type-caption-on-light text-capitalize'>{FDS.valueOrDash(r.BSP_MARKET_SHARE)}</small>
                    </Col>
                    <Col sm={2}>
                        <span className={`dot d100 ${r.BSP_TOTAL_RATE_LTM < 0.9 ? 'success' : 'warning'}`}></span>
                        {FDS.valueOrDash(r.BSP_TOTAL_RATE_LTM ? FDS.numberFormatTxt(r.BSP_TOTAL_RATE_LTM * 100, 2, '%') : r.BSP_TOTAL_RATE_LTM)}
                    </Col>
                </Row>
            </>
        )
    }

    useEffect(() => {
        console.log("KKK", props.data)
        setResponse(Object.keys(props.data).length === 0 ? { page1: { results: [] }, page2: { results: [] } } : props.data);
    }, [props.data]);

    return (
        <>
            {
                response.page1?.results?.length > 0 ?
                    <>
                        <Breadcrumb className='mt-3 ms-2'>
                            <Breadcrumb.Item href="#" active={isTMC === 0} onClick={() => {
                                const cl = window.document.getElementById("reportPageLeftToggleRef")?.classList;
                                cl?.add("static");
                                cl?.remove("min");
                                setCurrentPage3(1);
                                setTimeout(() => {
                                    setIsTMC(0);
                                }, 300);
                            }}>Data Status List</Breadcrumb.Item>
                            {isTMC >= 1 ? <Breadcrumb.Item href="#" active={isTMC === 1} onClick={() => { setIsTMC(1); setCurrentPage3(1); }}>{tmcs?.ADC_CORP_NM || "TMC"}</Breadcrumb.Item> : null}
                            {isTMC >= 2 ? <Breadcrumb.Item active={isTMC === 2}>{corps?.ADC_AGENCY_NAME || "CORPS"}</Breadcrumb.Item> : null}
                        </Breadcrumb>
                        {isTMC === 0 ?
                            <>
                                <Row>
                                    <Col className='ms-2 pt-3'>
                                        Records
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + 1, 0, null)}</b> -
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + itemPerPage < (response.page1?.results?.length || 0) ? (currentPage - 1) * itemPerPage + itemPerPage : (response.page1?.results?.length || 0), 0, null)}</b> of
                                        <b> {FDS.numberFormat(response.page1?.results?.length || 0, 0, null)}</b>
                                    </Col>
                                    <Col className='me-2 right'>
                                        <DynamicPagination className='mb-0' currentPage={currentPage} itemPerPage={itemPerPage} totalItems={response.page1?.results?.length || 0} onPageChange={(page: number) => setCurrentPage(page)} />
                                    </Col>
                                </Row>
                                <Row className="arcciCorpWrap m-2">
                                    <Col>
                                        {
                                            [...(response.page1?.results || [])]?.slice((currentPage - 1) * itemPerPage, (currentPage - 1) * itemPerPage + itemPerPage)
                                                .map((r: any, ri: number) => {
                                                    return (
                                                        <Row className='ciRow' role="button" key={ri} onClick={() => {
                                                            const cl = window.document.getElementById("reportPageLeftToggleRef")?.classList;
                                                            cl?.remove("static");
                                                            cl?.add("min");
                                                            setIsTMC(1);
                                                            setTmcs((prev: any) => {
                                                                return { ...r, tmcs: [...response.page2?.results?.filter((tmc: any) => r.ADC_CORP_NM === tmc.ADC_CORP_NM)] }
                                                            });
                                                        }}>
                                                            <Col md={4}>
                                                                <div>
                                                                    <b>{FDS.valueOrDash(r.ADC_CORP_NM)}</b>
                                                                    <small className='type-body-secondary-on-light ms-2 capitalize'>Corp</small>
                                                                </div>
                                                                <div>
                                                                    <span className={`dot ${status[r.AGREEMENT_STATUS]}`}></span>
                                                                    <small className='text-capitalize'>{FDS.valueOrDash(r.AGREEMENT_STATUS?.replace(/_/gi, " ")?.toLowerCase())}</small>
                                                                </div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div><label>Corporate Id</label></div>
                                                                <div className='type-body-secondary-on-light'><small>{FDS.valueOrDash(r.ADC_CARR_CORP_ID)}</small></div>
                                                            </Col>
                                                            <Col md={2}>
                                                                <div><label>DRA Count</label></div>
                                                                <div className='type-body-secondary-on-light'><small>{FDS.valueOrDash(r.count)}</small></div>
                                                            </Col>
                                                            <Col className='contactWrap' md={4}>
                                                                <Row>
                                                                    <Col className='initialWrap'>
                                                                        <div className='initial disabled'>{r.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}</div>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className='text-capitalize'>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER__C?.toLowerCase())}</div>
                                                                        <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(null)}</small></div>
                                                                        <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                                    </Col>
                                                                </Row>
                                                            </Col>
                                                        </Row>
                                                    )
                                                })
                                        }
                                    </Col>
                                </Row>
                                <Row>
                                    <Col className='ms-2'>
                                        Records
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + 1, 0, null)}</b> -
                                        <b> {FDS.numberFormat((currentPage - 1) * itemPerPage + itemPerPage < (response.page1?.results?.length || 0) ? (currentPage - 1) * itemPerPage + itemPerPage : (response.page1?.results?.length || 0), 0, null)}</b> of
                                        <b> {FDS.numberFormat(response.page1?.results?.length || 0, 0, null)}</b>
                                    </Col>
                                    <Col className='me-2 right'>
                                        <DynamicPagination currentPage={currentPage} itemPerPage={itemPerPage} totalItems={response.page1?.results?.length || 0} onPageChange={(page: number) => setCurrentPage(page)} />
                                    </Col>
                                </Row>
                            </> : null
                        }

                        {isTMC === 1 ?
                            <Row className="m-2 p-3" style={{ background: "white" }}>
                                <Col>
                                    <Row className='arcciCorpWrap mb-3 pb-2' style={{ background: "white", border: "none" }}>
                                        <Col md={9}>
                                            <Row>
                                                <Col style={{ maxWidth: "18px" }}>
                                                    <span className={`dot xl success mt-3 mr-2`}></span>
                                                </Col>
                                                <Col>
                                                    <h2 className='mb-0'>{FDS.valueOrDash(tmcs.ADC_CORP_NM)}</h2>
                                                    <div className='lessMargin type-body-secondary-on-light'><small>Corporate ID: {FDS.valueOrDash(tmcs.ADC_CARR_CORP_ID)}</small></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col className='contactWrap'>
                                            <Row>
                                                <Col className='initialWrap'>
                                                    <div className='initial disabled'>{tmcs.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}</div>
                                                </Col>
                                                <Col>
                                                    <div>{FDS.valueOrDash(tmcs.COMPANY_TRAVEL_MANAGER__C)}</div>
                                                    <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                    <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(tmcs.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    {
                                        tmcs.tmcs?.length > 0 ? [...(tmcs.tmcs || [])]?.map((r: any, ri: number) => {
                                            return (
                                                <Row key={ri} className='arcciCorpWrap tmc mb-3 boxShadow'>
                                                    <Col className='tmcLeft' md={3} role="button" onClick={() => {
                                                        setIsTMC(2);
                                                        setCorps((prev: any) => {
                                                            return { ...r, corps: [...response.page2?.results?.filter((corp: any) => r.ADC_AGENCY_NAME === corp.ADC_AGENCY_NAME)] }
                                                        });
                                                    }}>
                                                        <Row>
                                                            <Col style={{ maxWidth: "18px" }}>
                                                                <span className={`dot lg ${status[tmcs.status]}`}></span>
                                                            </Col>
                                                            <Col>
                                                                <div><b>{FDS.valueOrDash(r.ADC_AGENCY_NAME)}</b></div>
                                                                <div className='lessMargin type-body-secondary-on-light'><small>TMC</small></div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <Row className='contactWrap'>
                                                            <Col className='initialWrap'>
                                                                <div className='initial disabled'>
                                                                    {r.AGENCY_CONTACT_NAME__C?.match(/\b(\w)/g)?.join('')}
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <div>{FDS.valueOrDash(r.AGENCY_CONTACT_NAME__C)}</div>
                                                                <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                                <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(r.AGENCY_CONTACT_EMAIL__C)}</small></div>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                    <Col className='tmcRight' md={9}>
                                                        <Row className='pt-1 pb-1'>
                                                            <Col sm={2}>
                                                                <div><label>DRA Status</label></div>
                                                                <div className='type-caption-on-light'><small>{FDS.valueOrDash(r.AGREEMENT_STATUS__C)}</small></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Data Status</label></div>
                                                                <div className='type-caption-on-light'><small>NA</small></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">DRA Sent Date</label></div>
                                                                <div className='type-caption-on-light'><small>{FDS.valueOrDash(r.EFFECTIVE_DATE__C)}</small></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Approved Date</label></div>
                                                                <div className='type-caption-on-light'><small>{FDS.valueOrDash(r.SUBMIT_DATE)}</small></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Ticketing Begin Date</label></div>
                                                                <div className='type-caption-on-light'><small>{FDS.valueOrDash(r.RLSE_DT)}</small></div>
                                                            </Col>
                                                            <Col sm={2}>
                                                                <div><label htmlFor="">Ticketing End Date</label></div>
                                                                <div className='type-caption-on-light'><small>{FDS.valueOrDash(r.RLSE_END_DT)}</small></div>
                                                            </Col>
                                                        </Row>
                                                        <hr />
                                                        <div className='pt-3 pb-3'></div>
                                                        {statusTable(r)}
                                                    </Col>
                                                </Row>
                                            )
                                        }) :
                                            <div>
                                                TMC records are not available for the selected corporation.
                                            </div>
                                    }
                                </Col>
                            </Row> : null
                        }

                        {isTMC === 2 ?
                            <>
                                <Row className='arcciCorpWrap tmc m-2 pt-2 pb-2 mb-3' style={{ background: "white", border: "none" }}>
                                    <Col>
                                        <Row>
                                            <Col style={{ maxWidth: "18px" }}>
                                                <span className={`dot xl success mt-3 mr-2`}></span>
                                            </Col>
                                            <Col>
                                                <h2>{FDS.valueOrDash(corps.ADC_AGENCY_NAME)}</h2>
                                                <h6>TMC</h6>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col className='contactWrap' md={3}>
                                        <Row>
                                            <Col className='initialWrap'>
                                                <div className='initial disabled'>{corps.AGENCY_CONTACT_NAME__C?.match(/\b(\w)/g)?.join('')}</div>
                                            </Col>
                                            <Col>
                                                <div>{FDS.valueOrDash(corps.AGENCY_CONTACT_NAME__C?.name)}</div>
                                                <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(corps.AGENCY_CONTACT_EMAIL__C)}</small></div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                                <Row className="arcciCorpWrap tmc m-2" style={{ background: "white", border: "none" }}>
                                    <Col>
                                        <div className='pt-3'><h3>Associated Corporations</h3></div>
                                        <hr />
                                        <Row>
                                            <Col className='ms-0'>
                                                Records
                                                <b> {FDS.numberFormat((currentPage3 - 1) * itemPerPage3 + 1, 0, null)}</b> -
                                                <b> {FDS.numberFormat((currentPage3 - 1) * itemPerPage3 + itemPerPage3 < corps.corps?.length ? (currentPage3 - 1) * itemPerPage3 + itemPerPage3 : corps.corps?.length, 0, null)}</b> of
                                                <b> {FDS.numberFormat(corps.corps?.length, 0, null)}</b>
                                            </Col>
                                            <Col className='me-2 right'>
                                                <DynamicPagination currentPage={currentPage3} itemPerPage={itemPerPage3} totalItems={corps.corps?.length} onPageChange={(page: number) => setCurrentPage3(page)} />
                                            </Col>
                                        </Row>
                                        {
                                            [...(corps.corps || [])]?.slice((currentPage3 - 1) * itemPerPage3, (currentPage3 - 1) * itemPerPage3 + itemPerPage3)
                                                .map((r: any, ri: number) => {
                                                    return (
                                                        <>
                                                            <Row className='mb-3 pb-3' key={ri}>
                                                                <Col>
                                                                    <Row>
                                                                        <Col>
                                                                            <div className='mt-3'>
                                                                                <b>{FDS.valueOrDash(r.ADC_CORP_NM)}</b>
                                                                                <small className='type-body-secondary-on-light ms-2 capitalize'>{r.ADC_CARR_CORP_ID}</small>
                                                                            </div>
                                                                        </Col>
                                                                        <Col className='contactWrap' md={3}>
                                                                            <Row>
                                                                                <Col className='initialWrap'>
                                                                                    <div className='initial disabled'>{r.COMPANY_TRAVEL_MANAGER__C?.match(/\b(\w)/g)?.join('')}</div>
                                                                                </Col>
                                                                                <Col>
                                                                                    <div>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER__C)}</div>
                                                                                    <div className='lessMargin type-caption-on-light'><small>{ }</small></div>
                                                                                    <div className='lessMargin type-caption-on-light'><small>{FDS.valueOrDash(r.COMPANY_TRAVEL_MANAGER_EMAIL__C)}</small></div>
                                                                                </Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>

                                                                    <div className='pt-3 pb-3'></div>
                                                                    {statusTable(r)}
                                                                </Col>
                                                            </Row>
                                                            <hr />
                                                            <div className='pb-3 pt-3'></div>
                                                        </>
                                                    )
                                                })
                                        }
                                    </Col>
                                </Row>
                            </> : null
                        }
                    </> :
                    <Alert variant="info" className='mt-3'>No corporations are available for the selected filter criteria.</Alert>
            }
            {modalData?.data?.length > 0 &&
                <Modal
                    className="tcvModal"
                    show={modalData?.data?.length > 0}
                    onHide={() => setModalData({ data: [] })}
                >
                    <Modal.Header closeButton>
                        <Modal.Title>Tour Code Validation</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <FDS.BarChart
                            id="Bar_Chart_Id"
                            title="Bar Chart Title"
                            orientation="V"
                            sideBySide={true}
                            data={modalData?.data || []}
                            totalRecords={modalData?.data?.length || 0}
                            labels={{
                                x: "X Axis Label",
                                y: "Y Axis Label"
                            }}
                            keys={{
                                label: "label",
                                values: ["value"],
                                legends: ["Version 1 Legend Label"],
                                symbol: ""
                            }}
                            style={{
                                width: 600,
                                height: 500
                            }}
                            sortKey={""}
                        />
                    </Modal.Body>
                </Modal>
            }
        </>
    );
}