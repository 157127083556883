import React, { useRef, useEffect, useState } from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import * as FDS from '@arctravel/react-fds/lib';
import { templates } from '../utils';
import { ResultsPanel } from '../resultsPanel';
import { NewReportFilters } from '../newReportFilters';
import { useParams, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { isInternalAdminOrUser } from '../../okta/ls'

import './Reports.scss'
import Feedback from '../modals/Feedback';

declare const window: any;

const Reports = (props: any) => {
  const tRef: any = window.ENV.props?.reportTitles;
  const customersRef = useSelector((state: any) => state.customersRef);
  const [customer, setCustomer] = useState(customersRef.customer);
  const params: any = useParams();
  const lastModifiedDataTimeStamp = useSelector((state: any) => state.referenceDataRef[window.ENV.references[params.id === "ARCCI" ? 'lastmodifiedDrf' : "lastmodifiedTrip"]]);
  const history: any = useHistory()
  const [id, setId] = useState(params.id ? params.id : window.ENV.props.reportTitles.JOURNEY.id);
  const [template, setTemplate]: any = useState(templates[params.id]);

  const toggleBtn = useRef(null);
  const dispatch = useDispatch();

  const onReport = () => {
    if (!template.staticFilter) {
      const current: any = toggleBtn?.current;
      current?.classList.add('min');
    }
  }

  useEffect(() => {
    setCustomer(customersRef.customer);

    if (id.indexOf("_ESTIMATES") >= 0 && customersRef.customer?.modules?.indexOf(id.split("_").join(" INSIGHTS ")) < 0) {
      history.push(window.ENV.props.reportTitles.JOURNEY.id)
    }
  }, [customersRef.customer, history, id]);

  useEffect(() => {
    if (customer?.modules) {
      params.id === "ARCCIDS" 
      || customer?.modules?.indexOf(params.id.split("_")[0] + " PERFORMANCE") >= 0
      || params.id === "CARRIER_DASHBOARD" 
      || customer?.modules?.indexOf(params.id) >= 0 
      || customer?.modules?.indexOf(params.id + " INSIGHTS") >= 0 
      || customer?.modules?.indexOf(params.id.split("_")[0] + " INSIGHTS") >= 0 
      ? setId(params.id ? params.id : window.ENV.props.reportTitles.JOURNEY.id) : history.push("/")
    }
    setTemplate(templates[params.id]);
  }, [params.id, customer.modules, history]);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch({ type: "REPORT_IP", data: {} });
  }, [dispatch, id]);

  return (
    <React.Fragment>
      <Feedback page={params.id} />
      <FDS.FDSLevel mainTitle={tRef[id].mainTitle} subTitle={tRef[id].subTitle + (params.id === "ARCCIDS" ? ` - ${customersRef.viewAs?.orgName || customer.name}` : "")} type={template.onLoadRun ? "three" : "two"} name="reportingPage">
        {lastModifiedDataTimeStamp && <Row className="fds-pl-container dataRefresh">
          <Col style={{ color: template.onLoadRun ? "black" : "white" }}>
            Last Data Refresh {new Date(lastModifiedDataTimeStamp[0]?.date).toLocaleDateString()} {new Date(lastModifiedDataTimeStamp[0]?.date).toLocaleTimeString()}
          </Col>
        </Row>}
        <Container className={`fds-pl-container ${!lastModifiedDataTimeStamp ? "first" : ""} ${template.filtersOnTop && "p-0"} float`}>
          {template.filtersOnTop ?
            <div>
              <div className={`blankable ${template.onLoadRun || template.darkBackground ? "darkbg spacing pt-3" : ""}`}>
                {isInternalAdminOrUser() || Object.keys(customer).length > 0 ?
                  <NewReportFilters
                    params={id}
                    onLoadRun={template.onLoadRun}
                    onReport={onReport}
                    // customer={isInternalAdminOrUser() ? customers.customers?.filter((c: any) => c.org.toUpperCase() === customers.viewAs.org)[0] : customers.customer} />
                    customer={customer} />
                  : null}
              </div>
              <div>
                <ResultsPanel params={id} onLoadRun={template.onLoadRun} staticFilter={template.staticFilter} />
              </div>
            </div> :
            <Row className={`fds-pl-column p-0 mb-3 shadow ${template.onLoadRun || template.darkBackground ? "chartbg" : ""}`}>
              <Col id="reportPageLeftToggleRef" className={`leftWrap ${template.onLoadRun ? "min charts" : ""} ${template.staticFilter ? "static" : ""} ${id}`} ref={toggleBtn} style={{ minHeight: '450px' }}>
                {template.staticFilter ? null : <div className="minimize">
                  <Button className="minBtn" variant="primary" size="sm" onClick={(ev) => {
                    ev?.currentTarget?.parentElement?.parentElement?.classList?.toggle('min');
                    //ev?.currentTarget?.parentElement?.parentElement?.nextElementSibling?.firstElementChild?.classList?.toggle('position-absolute');
                  }}>
                    <i className={`${!template.onLoadRun ? "fds-glyphs-arrow-left3" : "fds-glyphs-filter3"}`}></i>
                  </Button>
                </div>}
                <div className="blankable">
                  {isInternalAdminOrUser() || Object.keys(customer).length > 0 ?
                    <NewReportFilters
                      params={id}
                      onLoadRun={template.onLoadRun}
                      onReport={onReport}
                      // customer={isInternalAdminOrUser() ? customers.customers?.filter((c: any) => c.org.toUpperCase() === customers.viewAs.org)[0] : customers.customer} />
                      customer={customer} />
                    : null}
                </div>
              </Col>
              <ResultsPanel params={id} onLoadRun={template.onLoadRun} staticFilter={template.staticFilter} />
            </Row>
          }
        </Container>
        <div></div>
      </FDS.FDSLevel>
    </React.Fragment >
  );
}

export default Reports;
