import React, { useState, useEffect, useRef } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { useSelector } from 'react-redux';

interface CheckReportReadyProps {
    reportType: string;
    onReady: any;
}

export const CheckReportReady = (props: CheckReportReadyProps) => {
    const notificationRef = useSelector((store: any) => store.notificationRef);
    const onReady: any = useRef(props.onReady);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        if (notificationRef.body?.notificationType === "requestComplete") {
            console.log("EVENT---", notificationRef);
            const tor = onReady.current;
            tor({ body: notificationRef.body, reportType: props.reportType });
        }
    }, [notificationRef, props.reportType, onReady]);

    return (
        <>
            <Modal aria-labelledby="contained-modal-title-vcenter" centered show={showModal} onHide={() => { setShowModal(false) }}>
                <Modal.Header closeButton>
                    <Modal.Title>Report Generation Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Your report generation still in progress. You can wait or can check later in History when the report is ready.
                </Modal.Body>
                <Modal.Footer>
                    <Button data-testid="waitButton" aria-label='Wait' variant="primary" onClick={() => props.onReady()}>
                        I will Wait
                    </Button>
                    <Button data-testid="waitButton" aria-label='Wait' variant="secondary" onClick={() => props.onReady()}>
                        I will Check Later
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
};