export const ap = {
    "summary": {
        "results": [
            {
                "summary": "summary",
                "blended_pay": 8157937,
                "blended_revenue": 7010378,
                "trip": 34677,
                "yr": 2000,
                "yr_prior": 1985,
                "yr_diff": 85,
                "yr_percent_diff": 0.85,
                "yq": 6521,
                "yq_prior": 8512,
                "yq_diff": 1951,
                "yq_percent_diff": -0.25,
                "commission": 85477,
                "blended_pay_prior": 10954124,
                "blended_revenue_prior": 9452625,
                "trip_prior": 45105,
                "commission_prior": 116261,
                "blended_pay_diff": -2796187,
                "blended_revenue_diff": -2442247,
                "trip_diff": -10428,
                "commission_diff": -30784,
                "blended_pay_percent_diff": -0.2553,
                "blended_revenue_percent_diff": -0.2584,
                "trip_percent_diff": -0.2312,
                "commission_percent_diff": -0.2648
            }
        ]
    },
    "agencyHomeOfficeBaseFare": {
        results: {
            label: "ROOT",
            children: [
                {
                    label: "Total Sales Agency Home Office",
                    children: [
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "9502541", value: 0.6, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 }
                    ]
                },
                {
                    label: "Prior Sales Agency Home Office",
                    children: [
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "9502541", value: 0.6, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 }
                    ]
                }
            ]
        }
    },
    "agencyHomeOfficeNetFare": {
        results: {
            label: "ROOT",
            children: [
                {
                    label: "Total Sales Agency Home Office",
                    children: [
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "9502541", value: 0.6, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 }
                    ]
                },
                {
                    label: "Prior Sales Agency Home Office",
                    children: [
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "9502541", value: 0.6, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 }
                    ]
                }
            ]
        }
    },
    "agencyHomeOffice": {
        results: {
            label: "ROOT",
            children: [
                {
                    label: "Total Sales Agency Home Office",
                    children: [
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "9502541", value: 0.6, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 }
                    ]
                },
                {
                    label: "Prior Sales Agency Home Office",
                    children: [
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "5022541", value: 0.2, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "6021541", value: 0.3, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "7022541", value: 0.4, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "8022541", value: 0.5, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 },
                        { label: "9502541", value: 0.6, value_prior: 0.11, value_diff: 59, value_percent_diff: 0.11 }
                    ]
                }
            ]
        }
    },
    "agencyNetFare": {
        results: {
            label: "ROOT",
            children: [
                {
                    label: "Agency",
                    children: [
                        { label: "5021541", value: 0.5, distribution: 800 },
                        { label: "6021541", value: 0.4, distribution: 900 },
                        { label: "7021541", value: 0.3, distribution: 700 },
                        { label: "8021541", value: 0.35, distribution: 600 },
                        { label: "9021541", value: 0.7, distribution: 500}
                    ]
                }
            ]
        }
    },
    "agencyBaseFare": {
        results: {
            label: "ROOT",
            children: [
                {
                    label: "Agency",
                    children: [
                        { label: "5021541", value: 0.5, distribution: 800 },
                        { label: "6021541", value: 0.4, distribution: 900 },
                        { label: "7021541", value: 0.3, distribution: 700 },
                        { label: "8021541", value: 0.35, distribution: 600 },
                        { label: "9021541", value: 0.7, distribution: 500}
                    ]
                }
            ]
        }
    },
    "agencySales": {
        results: {
            label: "ROOT",
            children: [
                {
                    label: "Agency",
                    children: [
                        { label: "5021541", value: 0.5, distribution: 800 },
                        { label: "6021541", value: 0.4, distribution: 900 },
                        { label: "7021541", value: 0.3, distribution: 700 },
                        { label: "8021541", value: 0.35, distribution: 600 },
                        { label: "9021541", value: 0.7, distribution: 500}
                    ]
                }
            ]
        }
    },
    "transactionType": {
        "results": [
            {
                "transaction": "Sales",
                "count": 33292,
                "count_prior": 44077,
                "count_diff": -10785,
                "count_percent_diff": -0.2447
            },
            {
                "transaction": "Exchanges",
                "count": 1652,
                "count_prior": 2095,
                "count_diff": -443,
                "count_percent_diff": -0.2115
            },
            {
                "transaction": "Refund",
                "count": 267,
                "count_prior": 1067,
                "count_diff": -800,
                "count_percent_diff": -0.7498
            }
        ]
    },
    "topAgencyNetFare": {
        "results": [
            {
                "agency": "11617270",
                "blended_pay": 1311773,
                "blended_pay_prior": 1927243,
                "blended_pay_diff": -615470,
                "blended_pay_percent_diff": -0.3194
            },
            {
                "agency": "13527231",
                "blended_pay": 982863,
                "blended_pay_prior": 1102222,
                "blended_pay_diff": -119359,
                "blended_pay_percent_diff": -0.1083
            },
            {
                "agency": "14644324",
                "blended_pay": 490690,
                "blended_pay_prior": 639234,
                "blended_pay_diff": -148544,
                "blended_pay_percent_diff": -0.2324
            },
            {
                "agency": "11578626",
                "blended_pay": 479619,
                "blended_pay_prior": 549033,
                "blended_pay_diff": -69414,
                "blended_pay_percent_diff": -0.1264
            },
            {
                "agency": "07560685",
                "blended_pay": 430637,
                "blended_pay_prior": 481485,
                "blended_pay_diff": -50848,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "agency": "22521623",
                "blended_pay": 322062,
                "blended_pay_prior": 414338,
                "blended_pay_diff": -92276,
                "blended_pay_percent_diff": -0.2227
            },
            {
                "agency": "10577055",
                "blended_pay": 278537,
                "blended_pay_prior": 32717,
                "blended_pay_diff": 245820,
                "blended_pay_percent_diff": 7.5135
            },
            {
                "agency": "50560985",
                "blended_pay": 217115,
                "blended_pay_prior": 348212,
                "blended_pay_diff": -131097,
                "blended_pay_percent_diff": -0.3765
            },
            {
                "agency": "03531500",
                "blended_pay": 170400,
                "blended_pay_prior": 230382,
                "blended_pay_diff": -59982,
                "blended_pay_percent_diff": -0.2604
            },
            {
                "agency": "50622036",
                "blended_pay": 152441,
                "blended_pay_prior": 238887,
                "blended_pay_diff": -86446,
                "blended_pay_percent_diff": -0.3619
            }
        ]
    },
    "topAgencyBaseFare": {
        "results": [
            {
                "agency": "11617270",
                "blended_pay": 1311773,
                "blended_pay_prior": 1927243,
                "blended_pay_diff": -615470,
                "blended_pay_percent_diff": -0.3194
            },
            {
                "agency": "13527231",
                "blended_pay": 982863,
                "blended_pay_prior": 1102222,
                "blended_pay_diff": -119359,
                "blended_pay_percent_diff": -0.1083
            },
            {
                "agency": "14644324",
                "blended_pay": 490690,
                "blended_pay_prior": 639234,
                "blended_pay_diff": -148544,
                "blended_pay_percent_diff": -0.2324
            },
            {
                "agency": "11578626",
                "blended_pay": 479619,
                "blended_pay_prior": 549033,
                "blended_pay_diff": -69414,
                "blended_pay_percent_diff": -0.1264
            },
            {
                "agency": "07560685",
                "blended_pay": 430637,
                "blended_pay_prior": 481485,
                "blended_pay_diff": -50848,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "agency": "22521623",
                "blended_pay": 322062,
                "blended_pay_prior": 414338,
                "blended_pay_diff": -92276,
                "blended_pay_percent_diff": -0.2227
            },
            {
                "agency": "10577055",
                "blended_pay": 278537,
                "blended_pay_prior": 32717,
                "blended_pay_diff": 245820,
                "blended_pay_percent_diff": 7.5135
            },
            {
                "agency": "50560985",
                "blended_pay": 217115,
                "blended_pay_prior": 348212,
                "blended_pay_diff": -131097,
                "blended_pay_percent_diff": -0.3765
            },
            {
                "agency": "03531500",
                "blended_pay": 170400,
                "blended_pay_prior": 230382,
                "blended_pay_diff": -59982,
                "blended_pay_percent_diff": -0.2604
            },
            {
                "agency": "50622036",
                "blended_pay": 152441,
                "blended_pay_prior": 238887,
                "blended_pay_diff": -86446,
                "blended_pay_percent_diff": -0.3619
            }
        ]
    },
    "topAgencySales": {
        "results": [
            {
                "agency": "11617270",
                "blended_pay": 1311773,
                "blended_pay_prior": 1927243,
                "blended_pay_diff": -615470,
                "blended_pay_percent_diff": -0.3194
            },
            {
                "agency": "13527231",
                "blended_pay": 982863,
                "blended_pay_prior": 1102222,
                "blended_pay_diff": -119359,
                "blended_pay_percent_diff": -0.1083
            },
            {
                "agency": "14644324",
                "blended_pay": 490690,
                "blended_pay_prior": 639234,
                "blended_pay_diff": -148544,
                "blended_pay_percent_diff": -0.2324
            },
            {
                "agency": "11578626",
                "blended_pay": 479619,
                "blended_pay_prior": 549033,
                "blended_pay_diff": -69414,
                "blended_pay_percent_diff": -0.1264
            },
            {
                "agency": "07560685",
                "blended_pay": 430637,
                "blended_pay_prior": 481485,
                "blended_pay_diff": -50848,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "agency": "22521623",
                "blended_pay": 322062,
                "blended_pay_prior": 414338,
                "blended_pay_diff": -92276,
                "blended_pay_percent_diff": -0.2227
            },
            {
                "agency": "10577055",
                "blended_pay": 278537,
                "blended_pay_prior": 32717,
                "blended_pay_diff": 245820,
                "blended_pay_percent_diff": 7.5135
            },
            {
                "agency": "50560985",
                "blended_pay": 217115,
                "blended_pay_prior": 348212,
                "blended_pay_diff": -131097,
                "blended_pay_percent_diff": -0.3765
            },
            {
                "agency": "03531500",
                "blended_pay": 170400,
                "blended_pay_prior": 230382,
                "blended_pay_diff": -59982,
                "blended_pay_percent_diff": -0.2604
            },
            {
                "agency": "50622036",
                "blended_pay": 152441,
                "blended_pay_prior": 238887,
                "blended_pay_diff": -86446,
                "blended_pay_percent_diff": -0.3619
            }
        ]
    },
    "topAgencyCommission": {
        "results": [
            {
                "commission": "11617270",
                "commission_pay": 11773,
                "commission_pay_prior": 7243,
                "commission_pay_diff": -470,
                "commission_pay_percent_diff": -0.4
            },
            {
                "commission": "13527231",
                "commission_pay": 2863,
                "commission_pay_prior": 2222,
                "commission_pay_diff": -359,
                "commission_pay_percent_diff": -0.3
            },
            {
                "commission": "14644324",
                "commission_pay": 690,
                "commission_pay_prior": 234,
                "commission_pay_diff": -544,
                "commission_pay_percent_diff": -0.24
            },
            {
                "commission": "11578626",
                "commission_pay": 9619,
                "commission_pay_prior": 9033,
                "commission_pay_diff": -414,
                "commission_pay_percent_diff": -0.64
            },
            {
                "commission": "07560685",
                "commission_pay": 4307,
                "commission_pay_prior": 1485,
                "commission_pay_diff": -848,
                "commission_pay_percent_diff": -0.56
            },
            {
                "commission": "22521623",
                "commission_pay": 2062,
                "commission_pay_prior": 3338,
                "commission_pay_diff": -276,
                "commission_pay_percent_diff": -0.27
            },
            {
                "commission": "10577055",
                "commission_pay": 8537,
                "commission_pay_prior": 7717,
                "commission_pay_diff": 820,
                "commission_pay_percent_diff": -0.135
            },
            {
                "commission": "50560985",
                "commission_pay": 7115,
                "commission_pay_prior": 6212,
                "commission_pay_diff": -297,
                "commission_pay_percent_diff": -0.35
            },
            {
                "commission": "03531500",
                "commission_pay": 400,
                "commission_pay_prior": 382,
                "commission_pay_diff": 82,
                "commission_pay_percent_diff": -0.2
            },
            {
                "commission": "50622036",
                "commission_pay": 2441,
                "commission_pay_prior": 3887,
                "commission_pay_diff": -1446,
                "commission_pay_percent_diff": -0.3619
            }
        ]
    },
    "netFaretrends": {
        results: [
            {
                "timeframe": "Jan",
                "blended_pay": 1699278,
                "blended_pay_prior": 277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Feb",
                "blended_pay": 2356683,
                "blended_pay_prior": 4370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            },
            {
                "timeframe": "Mar",
                "blended_pay": 1699278,
                "blended_pay_prior": 3277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Apr",
                "blended_pay": 5356683,
                "blended_pay_prior": 7370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            },
            {
                "timeframe": "May",
                "blended_pay": 2699278,
                "blended_pay_prior": 3277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Jun",
                "blended_pay": 6356683,
                "blended_pay_prior": 5370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            }
        ]
    },
    "baseFaretrends": {
        results: [
            {
                "timeframe": "Jan",
                "blended_pay": 1699278,
                "blended_pay_prior": 277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Feb",
                "blended_pay": 2356683,
                "blended_pay_prior": 4370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            },
            {
                "timeframe": "Mar",
                "blended_pay": 1699278,
                "blended_pay_prior": 3277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Apr",
                "blended_pay": 5356683,
                "blended_pay_prior": 7370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            },
            {
                "timeframe": "May",
                "blended_pay": 2699278,
                "blended_pay_prior": 3277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Jun",
                "blended_pay": 6356683,
                "blended_pay_prior": 5370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            }
        ]
    },
    "trends": {
        results: [
            {
                "timeframe": "Jan",
                "blended_pay": 1699278,
                "blended_pay_prior": 277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Feb",
                "blended_pay": 2356683,
                "blended_pay_prior": 4370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            },
            {
                "timeframe": "Mar",
                "blended_pay": 1699278,
                "blended_pay_prior": 3277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Apr",
                "blended_pay": 5356683,
                "blended_pay_prior": 7370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            },
            {
                "timeframe": "May",
                "blended_pay": 2699278,
                "blended_pay_prior": 3277616,
                "blended_pay_diff": -1578338,
                "blended_pay_percent_diff": -0.4816
            },
            {
                "timeframe": "Jun",
                "blended_pay": 6356683,
                "blended_pay_prior": 5370341,
                "blended_pay_diff": -1013658,
                "blended_pay_percent_diff": -0.1375
            }
        ]
    },
    "topOriginNetFare": {
        "results": [
            {
                "org": "DFW",
                "blended_pay": 2355861,
                "blended_pay_prior": 3171719,
                "blended_pay_diff": -815858,
                "blended_pay_percent_diff": -0.2572
            },
            {
                "org": "SFO",
                "blended_pay": 2220505,
                "blended_pay_prior": 3400358,
                "blended_pay_diff": -1179853,
                "blended_pay_percent_diff": -0.347
            },
            {
                "org": "IAD",
                "blended_pay": 1920734,
                "blended_pay_prior": 2391240,
                "blended_pay_diff": -470506,
                "blended_pay_percent_diff": -0.1968
            },
            {
                "org": "IAH",
                "blended_pay": 651162,
                "blended_pay_prior": 816181,
                "blended_pay_diff": -165019,
                "blended_pay_percent_diff": -0.2022
            },
            {
                "org": "LAX",
                "blended_pay": 393944,
                "blended_pay_prior": 306395,
                "blended_pay_diff": 87549,
                "blended_pay_percent_diff": 0.2857
            },
            {
                "org": "JFK",
                "blended_pay": 357329,
                "blended_pay_prior": 456556,
                "blended_pay_diff": -99227,
                "blended_pay_percent_diff": -0.2173
            },
            {
                "org": "CLT",
                "blended_pay": 247729,
                "blended_pay_prior": 394141,
                "blended_pay_diff": -146412,
                "blended_pay_percent_diff": -0.3715
            },
            {
                "org": "RSW",
                "blended_pay": 3361,
                "blended_pay_prior": 3758,
                "blended_pay_diff": -397,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "org": "DCA",
                "blended_pay": 1310,
                "blended_pay_prior": 1810,
                "blended_pay_diff": -500,
                "blended_pay_percent_diff": -0.2762
            },
            {
                "org": "RIC",
                "blended_pay": 1044,
                "blended_pay_prior": null,
                "blended_pay_diff": 1044,
                "blended_pay_percent_diff": 1
            }
        ]
    },
    "topOriginBaseFare": {
        "results": [
            {
                "org": "DFW",
                "blended_pay": 2355861,
                "blended_pay_prior": 3171719,
                "blended_pay_diff": -815858,
                "blended_pay_percent_diff": -0.2572
            },
            {
                "org": "SFO",
                "blended_pay": 2220505,
                "blended_pay_prior": 3400358,
                "blended_pay_diff": -1179853,
                "blended_pay_percent_diff": -0.347
            },
            {
                "org": "IAD",
                "blended_pay": 1920734,
                "blended_pay_prior": 2391240,
                "blended_pay_diff": -470506,
                "blended_pay_percent_diff": -0.1968
            },
            {
                "org": "IAH",
                "blended_pay": 651162,
                "blended_pay_prior": 816181,
                "blended_pay_diff": -165019,
                "blended_pay_percent_diff": -0.2022
            },
            {
                "org": "LAX",
                "blended_pay": 393944,
                "blended_pay_prior": 306395,
                "blended_pay_diff": 87549,
                "blended_pay_percent_diff": 0.2857
            },
            {
                "org": "JFK",
                "blended_pay": 357329,
                "blended_pay_prior": 456556,
                "blended_pay_diff": -99227,
                "blended_pay_percent_diff": -0.2173
            },
            {
                "org": "CLT",
                "blended_pay": 247729,
                "blended_pay_prior": 394141,
                "blended_pay_diff": -146412,
                "blended_pay_percent_diff": -0.3715
            },
            {
                "org": "RSW",
                "blended_pay": 3361,
                "blended_pay_prior": 3758,
                "blended_pay_diff": -397,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "org": "DCA",
                "blended_pay": 1310,
                "blended_pay_prior": 1810,
                "blended_pay_diff": -500,
                "blended_pay_percent_diff": -0.2762
            },
            {
                "org": "RIC",
                "blended_pay": 1044,
                "blended_pay_prior": null,
                "blended_pay_diff": 1044,
                "blended_pay_percent_diff": 1
            }
        ]
    },
    "topOriginSales": {
        "results": [
            {
                "org": "DFW",
                "blended_pay": 2355861,
                "blended_pay_prior": 3171719,
                "blended_pay_diff": -815858,
                "blended_pay_percent_diff": -0.2572
            },
            {
                "org": "SFO",
                "blended_pay": 2220505,
                "blended_pay_prior": 3400358,
                "blended_pay_diff": -1179853,
                "blended_pay_percent_diff": -0.347
            },
            {
                "org": "IAD",
                "blended_pay": 1920734,
                "blended_pay_prior": 2391240,
                "blended_pay_diff": -470506,
                "blended_pay_percent_diff": -0.1968
            },
            {
                "org": "IAH",
                "blended_pay": 651162,
                "blended_pay_prior": 816181,
                "blended_pay_diff": -165019,
                "blended_pay_percent_diff": -0.2022
            },
            {
                "org": "LAX",
                "blended_pay": 393944,
                "blended_pay_prior": 306395,
                "blended_pay_diff": 87549,
                "blended_pay_percent_diff": 0.2857
            },
            {
                "org": "JFK",
                "blended_pay": 357329,
                "blended_pay_prior": 456556,
                "blended_pay_diff": -99227,
                "blended_pay_percent_diff": -0.2173
            },
            {
                "org": "CLT",
                "blended_pay": 247729,
                "blended_pay_prior": 394141,
                "blended_pay_diff": -146412,
                "blended_pay_percent_diff": -0.3715
            },
            {
                "org": "RSW",
                "blended_pay": 3361,
                "blended_pay_prior": 3758,
                "blended_pay_diff": -397,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "org": "DCA",
                "blended_pay": 1310,
                "blended_pay_prior": 1810,
                "blended_pay_diff": -500,
                "blended_pay_percent_diff": -0.2762
            },
            {
                "org": "RIC",
                "blended_pay": 1044,
                "blended_pay_prior": null,
                "blended_pay_diff": 1044,
                "blended_pay_percent_diff": 1
            }
        ]
    },
    "topDestinationNetFare": {
        "results": [
            {
                "dest": "JFK",
                "blended_pay": 2355861,
                "blended_pay_prior": 3171719,
                "blended_pay_diff": -815858,
                "blended_pay_percent_diff": -0.2572
            },
            {
                "dest": "LAX",
                "blended_pay": 2220505,
                "blended_pay_prior": 3400358,
                "blended_pay_diff": -1179853,
                "blended_pay_percent_diff": -0.347
            },
            {
                "dest": "IAD",
                "blended_pay": 1920734,
                "blended_pay_prior": 2391240,
                "blended_pay_diff": -470506,
                "blended_pay_percent_diff": -0.1968
            },
            {
                "dest": "IAH",
                "blended_pay": 651162,
                "blended_pay_prior": 816181,
                "blended_pay_diff": -165019,
                "blended_pay_percent_diff": -0.2022
            },
            {
                "dest": "DFW",
                "blended_pay": 393944,
                "blended_pay_prior": 306395,
                "blended_pay_diff": 87549,
                "blended_pay_percent_diff": 0.2857
            },
            {
                "dest": "SFO",
                "blended_pay": 357329,
                "blended_pay_prior": 456556,
                "blended_pay_diff": -99227,
                "blended_pay_percent_diff": -0.2173
            },
            {
                "dest": "CLT",
                "blended_pay": 247729,
                "blended_pay_prior": 394141,
                "blended_pay_diff": -146412,
                "blended_pay_percent_diff": -0.3715
            },
            {
                "dest": "RSW",
                "blended_pay": 3361,
                "blended_pay_prior": 3758,
                "blended_pay_diff": -397,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "dest": "DCA",
                "blended_pay": 1310,
                "blended_pay_prior": 1810,
                "blended_pay_diff": -500,
                "blended_pay_percent_diff": -0.2762
            },
            {
                "dest": "RIC",
                "blended_pay": 1044,
                "blended_pay_prior": null,
                "blended_pay_diff": 1044,
                "blended_pay_percent_diff": 1
            }
        ]
    },
    "topDestinationBaseFare": {
        "results": [
            {
                "dest": "JFK",
                "blended_pay": 2355861,
                "blended_pay_prior": 3171719,
                "blended_pay_diff": -815858,
                "blended_pay_percent_diff": -0.2572
            },
            {
                "dest": "LAX",
                "blended_pay": 2220505,
                "blended_pay_prior": 3400358,
                "blended_pay_diff": -1179853,
                "blended_pay_percent_diff": -0.347
            },
            {
                "dest": "IAD",
                "blended_pay": 1920734,
                "blended_pay_prior": 2391240,
                "blended_pay_diff": -470506,
                "blended_pay_percent_diff": -0.1968
            },
            {
                "dest": "IAH",
                "blended_pay": 651162,
                "blended_pay_prior": 816181,
                "blended_pay_diff": -165019,
                "blended_pay_percent_diff": -0.2022
            },
            {
                "dest": "DFW",
                "blended_pay": 393944,
                "blended_pay_prior": 306395,
                "blended_pay_diff": 87549,
                "blended_pay_percent_diff": 0.2857
            },
            {
                "dest": "SFO",
                "blended_pay": 357329,
                "blended_pay_prior": 456556,
                "blended_pay_diff": -99227,
                "blended_pay_percent_diff": -0.2173
            },
            {
                "dest": "CLT",
                "blended_pay": 247729,
                "blended_pay_prior": 394141,
                "blended_pay_diff": -146412,
                "blended_pay_percent_diff": -0.3715
            },
            {
                "dest": "RSW",
                "blended_pay": 3361,
                "blended_pay_prior": 3758,
                "blended_pay_diff": -397,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "dest": "DCA",
                "blended_pay": 1310,
                "blended_pay_prior": 1810,
                "blended_pay_diff": -500,
                "blended_pay_percent_diff": -0.2762
            },
            {
                "dest": "RIC",
                "blended_pay": 1044,
                "blended_pay_prior": null,
                "blended_pay_diff": 1044,
                "blended_pay_percent_diff": 1
            }
        ]
    },
    "topDestinationSales": {
        "results": [
            {
                "dest": "JFK",
                "blended_pay": 2355861,
                "blended_pay_prior": 3171719,
                "blended_pay_diff": -815858,
                "blended_pay_percent_diff": -0.2572
            },
            {
                "dest": "LAX",
                "blended_pay": 2220505,
                "blended_pay_prior": 3400358,
                "blended_pay_diff": -1179853,
                "blended_pay_percent_diff": -0.347
            },
            {
                "dest": "IAD",
                "blended_pay": 1920734,
                "blended_pay_prior": 2391240,
                "blended_pay_diff": -470506,
                "blended_pay_percent_diff": -0.1968
            },
            {
                "dest": "IAH",
                "blended_pay": 651162,
                "blended_pay_prior": 816181,
                "blended_pay_diff": -165019,
                "blended_pay_percent_diff": -0.2022
            },
            {
                "dest": "DFW",
                "blended_pay": 393944,
                "blended_pay_prior": 306395,
                "blended_pay_diff": 87549,
                "blended_pay_percent_diff": 0.2857
            },
            {
                "dest": "SFO",
                "blended_pay": 357329,
                "blended_pay_prior": 456556,
                "blended_pay_diff": -99227,
                "blended_pay_percent_diff": -0.2173
            },
            {
                "dest": "CLT",
                "blended_pay": 247729,
                "blended_pay_prior": 394141,
                "blended_pay_diff": -146412,
                "blended_pay_percent_diff": -0.3715
            },
            {
                "dest": "RSW",
                "blended_pay": 3361,
                "blended_pay_prior": 3758,
                "blended_pay_diff": -397,
                "blended_pay_percent_diff": -0.1056
            },
            {
                "dest": "DCA",
                "blended_pay": 1310,
                "blended_pay_prior": 1810,
                "blended_pay_diff": -500,
                "blended_pay_percent_diff": -0.2762
            },
            {
                "dest": "RIC",
                "blended_pay": 1044,
                "blended_pay_prior": null,
                "blended_pay_diff": 1044,
                "blended_pay_percent_diff": 1
            }
        ]
    },
    "netFareByService": {
        "results": [
            {
                "class": "Business",
                "blended_pay": 85596,
                "blended_pay_prior": 55002,
                "blended_pay_diff": -33400,
                "blended_pay_percent_diff": -0.41
            },
            {
                "class": "Economy",
                "blended_pay": 116547,
                "blended_pay_prior": 140411,
                "blended_pay_diff": 33145,
                "blended_pay_percent_diff": -0.35
            },
            {
                "class": "First",
                "blended_pay": 25631,
                "blended_pay_prior": 35100,
                "blended_pay_diff": 3551,
                "blended_pay_percent_diff": -0.2676
            }, {
                "class": "Premium",
                "blended_pay": 10000,
                "blended_pay_prior": 12000,
                "blended_pay_diff": 2000,
                "blended_pay_percent_diff": -0.025
            },
            {
                "class": "Other",
                "blended_pay": 3000,
                "blended_pay_prior": 2500,
                "blended_pay_diff": 500,
                "blended_pay_percent_diff": -0.1
            }
        ]
    },
    "baseFareByService": {
        "results": [
            {
                "class": "Business",
                "blended_pay": 85596,
                "blended_pay_prior": 55002,
                "blended_pay_diff": -33400,
                "blended_pay_percent_diff": -0.41
            },
            {
                "class": "Economy",
                "blended_pay": 116547,
                "blended_pay_prior": 140411,
                "blended_pay_diff": 33145,
                "blended_pay_percent_diff": -0.35
            },
            {
                "class": "First",
                "blended_pay": 25631,
                "blended_pay_prior": 35100,
                "blended_pay_diff": 3551,
                "blended_pay_percent_diff": -0.2676
            }, {
                "class": "Premium",
                "blended_pay": 10000,
                "blended_pay_prior": 12000,
                "blended_pay_diff": 2000,
                "blended_pay_percent_diff": -0.025
            },
            {
                "class": "Other",
                "blended_pay": 3000,
                "blended_pay_prior": 2500,
                "blended_pay_diff": 500,
                "blended_pay_percent_diff": -0.1
            }
        ]
    },
    "salesByService": {
        "results": [
            {
                "class": "Business",
                "blended_pay": 85596,
                "blended_pay_prior": 55002,
                "blended_pay_diff": -33400,
                "blended_pay_percent_diff": -0.41
            },
            {
                "class": "Economy",
                "blended_pay": 116547,
                "blended_pay_prior": 140411,
                "blended_pay_diff": 33145,
                "blended_pay_percent_diff": -0.35
            },
            {
                "class": "First",
                "blended_pay": 25631,
                "blended_pay_prior": 35100,
                "blended_pay_diff": 3551,
                "blended_pay_percent_diff": -0.2676
            }, {
                "class": "Premium",
                "blended_pay": 10000,
                "blended_pay_prior": 12000,
                "blended_pay_diff": 2000,
                "blended_pay_percent_diff": -0.025
            },
            {
                "class": "Other",
                "blended_pay": 3000,
                "blended_pay_prior": 2500,
                "blended_pay_diff": 500,
                "blended_pay_percent_diff": -0.1
            }
        ]
    },
    "advance_purchase_range": {
        "results": [
            {
                "advance_purchase_range": ">60",
                "count": 34644,
                "count_prior": 38676,
                "count_diff": -4032,
                "count_percent_diff": -0.1043
            },
            {
                "advance_purchase_range": "31-60 days",
                "count": 40,
                "count_prior": 7043,
                "count_diff": -7003,
                "count_percent_diff": -0.9943
            },
            {
                "advance_purchase_range": "3-6 days",
                "count": 22,
                "count_prior": 66,
                "count_diff": -44,
                "count_percent_diff": -0.6667
            },
            {
                "advance_purchase_range": "0-2 days",
                "count": 14,
                "count_prior": 31,
                "count_diff": -17,
                "count_percent_diff": -0.5484
            },
            {
                "advance_purchase_range": "14-20 days",
                "count": 10,
                "count_prior": 16,
                "count_diff": -6,
                "count_percent_diff": -0.375
            },
            {
                "advance_purchase_range": "21-30 days",
                "count": 9,
                "count_prior": 35,
                "count_diff": -26,
                "count_percent_diff": -0.7429
            },
            {
                "advance_purchase_range": "7-13 days",
                "count": 8,
                "count_prior": 33,
                "count_diff": -25,
                "count_percent_diff": -0.7576
            }
        ]
    },    
    "yq": {
        "results": [
            {
                "transaction": "Sales",
                "fare": 226,                
                "fare_prior": 118298,
                "fare_diff": -33072,
                "fare_percent_diff": -0.2796
            },
            {
                "transaction": "Exchanges",                
                "fare": 50,
                "fare_prior": 0,
                "fare_diff": 0,
                "fare_percent_diff": 0
            },
            {
                "transaction": "Refund",                
                "fare": 20,
                "fare_prior": -3964,
                "fare_diff": 3844,
                "fare_percent_diff": -0.9697
            }
        ]
    },
    "yr": {
        "results": [
            {
                "transaction": "Sales",
                "commission": 85226,
                "commission_prior": 118298,
                "commission_diff": -33072,
                "commission_percent_diff": -0.2796
            },
            {
                "transaction": "Exchanges",
                "commission": 0,
                "commission_prior": 0,
                "commission_diff": 0,
                "commission_percent_diff": 0
            },
            {
                "transaction": "Refund",
                "commission": -120,
                "commission_prior": -3964,
                "commission_diff": 3844,
                "commission_percent_diff": -0.9697
            }
        ]
    }
}